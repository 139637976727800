import { render, staticRenderFns } from "./ResetOrderGrid.vue?vue&type=template&id=561c018e&"
import script from "./ResetOrderGrid.vue?vue&type=script&lang=js&"
export * from "./ResetOrderGrid.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2447407122/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('561c018e')) {
      api.createRecord('561c018e', component.options)
    } else {
      api.reload('561c018e', component.options)
    }
    module.hot.accept("./ResetOrderGrid.vue?vue&type=template&id=561c018e&", function () {
      api.rerender('561c018e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dialogs/ResetOrderGrid.vue"
export default component.exports